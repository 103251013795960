import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caustic-soda',
  templateUrl: './caustic-soda.component.html',
  styleUrls: ['./caustic-soda.component.scss']
})
export class CausticSodaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
