import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../global.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor(public gservice:GlobalService) { }

  ngOnInit() {
  }

  plantDetails = [
    {
      "tittle":"Plant Make",
      "details":"Aquarius"
    },
    {
      "tittle":"Rated Capacity of Plants",
      "details":"50 cum/hr"
    },
    {
      "tittle":"No. of Transit Mixers",
      "details":"20 Nos"
    },
    {
      "tittle":"No. of Concrete Pumps",
      "details":"5 Nos"
    },
    {
      "tittle":"Power",
      "details":"220 KV"
    },
    {
      "tittle":"Laboratory",
      "details":"Well-equipped lab"
    },
    {
      "tittle":"Other Equipment",
      "details":"Front-end Loaders, Cement feeding equipment"
    },
    {
      "tittle":"Raw Material Storage",
      "details":"Separate bins and silos for every material"
    },
    {
      "tittle":"Dispatch Control",
      "details":"Mobile phones & GPRS"
    },
    
  ]

  labDetails = [
    "Fully equipped lab to carry out various tests on concrete and on constituent raw materials.",
    "Concrete mixes of various grades are designed in laboratory as per established procedures to comply with BIS specifications.",
    "Research on alternative materials for concrete like steel slag,recycled aggregates etc.",
    "Introduce E-Trial Mix.We can do the trail mixes at your premises",
    "Research on alternative materials like Steel slag, Recycled Aggregates etc.",
    "Technical and Laboratory manuals based on IS Standards.",
  ]

}
