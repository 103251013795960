import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit() {

    this.contactForm = this.fb.group({
      name: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      email: [null, [Validators.required, Validators.email]],
      message: [null, [Validators.required]],
      mobile: [null, [Validators.required, Validators.pattern("[0-9 ]{10}")]]
    });
  }

  sendEmail() {
    if (this.contactForm.invalid) {
      alert("Please enter proper inputs");
      return
    }
    this.http.post('http://13.127.19.165:3001/contact', this.contactForm.value)
      .subscribe(res => {
        console.log(res)
        this.contactForm.reset();
        alert("Sales team will contact you shortly. Thank you");
      }, err => {
        console.log(err)
        this.contactForm.reset();
        alert("Please try again after some time");
      })
  }
}
