import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  mobilenav = false
  openNav(){
    this.mobilenav = true
    console.log("hello");
    console.log("hello")
  }
  closeNav(){
    this.mobilenav = false
  }

  services = [
    {
      "heading":"READY MIX CONCRETE SERVICE(RMC)",
      "image":"../../../../assets/concrete.jpg",
      "description":"Mark Con Mix provides high quality, precision, timely, and uninterrupted supply of Ready-Mix Concrete as per the requirements of the customer for affordable prices. Ready Mixed Concrete is manufactured at modern plants that have state-of-the-art machinery and equipment by machinery suppliers of global repute with automated Batching Plants."  
    },
    {
      "heading":"CONCRETE BRICKS",
      "image":"../../../../assets/concrete.jpeg",
      "description":"In addition to our distinct Ready Mic Concrete Service, we also provide a wide range of concrete blocks available in different sizes and designs.Concrete blocks manufactured by us are weather resistance, low breakage, low wastage, and high strength."  
    },
    {
      "heading":"CRUSHERS",
      "image":"../../../../assets/crushers.jpg",
      "description":"Mark Con Mix also possesses Crushers and manufactures crushed stone aggregates and quarry dust that reflect engineering precision, impeccable quality, and technical excellence.We have built a reputation for maintaining excellence in quality and execution by providing the best customer experience and the best class products."  
    }
  ]


  clients = [
    "../../../../assets/clients/1.jpeg","../../../../assets/clients/2.png","../../../../assets/clients/3.jpeg","../../../../assets/clients/4.jpeg","../../../../assets/clients/5.jpeg","../../../../assets/clients/6.jpeg","../../../../assets/clients/7.jpeg"
  ]
}
