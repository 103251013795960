import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deminaralised-water',
  templateUrl: './deminaralised-water.component.html',
  styleUrls: ['./deminaralised-water.component.scss']
})
export class DeminaralisedWaterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
