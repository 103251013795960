import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxCarouselModule } from 'ngx-carousel';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import 'hammerjs';

import { MainmoduleRoutingModule } from './mainmodule-routing.module';
import { ContactComponent } from './contact/contact.component';
import { ClientComponent } from './client/client.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { ProductComponent } from './product/product.component';
import { SlickModule } from 'ngx-slick';
import { DistilleadWaterComponent } from './distillead-water/distillead-water.component';
import { DeminaralisedWaterComponent } from './deminaralised-water/deminaralised-water.component';
import { IndustrialRoComponent } from './industrial-ro/industrial-ro.component';
import { SanitizerComponent } from './sanitizer/sanitizer.component';
import { AntiscaleforMembraneComponent } from './antiscalefor-membrane/antiscalefor-membrane.component';
import { CausticSodaComponent } from './caustic-soda/caustic-soda.component';
import { RoDrinkingComponent } from './ro-drinking/ro-drinking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


class GalleryHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 31 // this code enables all directions
    }
  };
}

@NgModule({
  declarations: [ContactComponent, ClientComponent, LandingpageComponent, ProductComponent, DistilleadWaterComponent, DeminaralisedWaterComponent, IndustrialRoComponent, SanitizerComponent, AntiscaleforMembraneComponent, CausticSodaComponent, RoDrinkingComponent],
  imports: [
    CommonModule,
    MainmoduleRoutingModule,
    NgxCarouselModule,
    SlickModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule

  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: GalleryHammerConfig
  }]
})



export class MainmoduleModule { }
