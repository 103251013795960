import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distillead-water',
  templateUrl: './distillead-water.component.html',
  styleUrls: ['./distillead-water.component.scss']
})
export class DistilleadWaterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
