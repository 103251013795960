import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactComponent } from './contact/contact.component';
import { ClientComponent } from './client/client.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { ProductComponent } from './product/product.component';
import { DistilleadWaterComponent } from './distillead-water/distillead-water.component';
import { DeminaralisedWaterComponent } from './deminaralised-water/deminaralised-water.component';
import { IndustrialRoComponent } from './industrial-ro/industrial-ro.component';
import { SanitizerComponent } from './sanitizer/sanitizer.component';
import { AntiscaleforMembraneComponent } from './antiscalefor-membrane/antiscalefor-membrane.component';
import { CausticSodaComponent } from './caustic-soda/caustic-soda.component';
import { RoDrinkingComponent } from './ro-drinking/ro-drinking.component';
const routes: Routes = [
   {
     path:'',
     component:LandingpageComponent
   },
   {
     path:'products',
     component:ProductComponent
   },
   {
    path:'client',
    component:ClientComponent
   },
   {
    path:'contact',
    component:ContactComponent
   },
   {
    path:'distilledwater',
    component:DistilleadWaterComponent
   },
   {
    path:'deminaralisedwater',
    component:DeminaralisedWaterComponent
   },
   {
    path:'industrialro',
    component:IndustrialRoComponent
   },

   {
    path:'sanitizer',
    component:SanitizerComponent
   },
   {
    path:'antiscaleformembrane',
    component:AntiscaleforMembraneComponent
   },
   {
    path:'causticsoda',
    component:CausticSodaComponent
   },
   {
    path:'rofordrinkingwater',
    component:RoDrinkingComponent
   },
   
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainmoduleRoutingModule { }
