import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxCarouselModule } from 'ngx-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { MainlayoutComponent } from './layouts/mainlayout/mainlayout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainmoduleModule } from  './modules/mainmodule/mainmodule.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainlayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainmoduleModule,
    NgxCarouselModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
